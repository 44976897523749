import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
const Header = React.lazy(() => import('./components/Header/Header'));
const Footer = React.lazy(() => import('./components/Footer/Footer'));

// Utilizando React.lazy() para cargar las páginas bajo demanda
const Home = lazy(() => import('./pages/Home'));
const Felicidades = lazy(() => import('./pages/Felicidades'));
const Calendario = lazy(() => import('./pages/Calendario'));

function App() {
  return (
    <Router>
      <Header />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/felicidades" element={<Felicidades />} />
          <Route path="/calendario" element={<Calendario />} />
          {/* Define other routes here */}
        </Routes>
      </Suspense>
      <Footer />
    </Router>
  );
}

export default App;